function getNextSlide(currentSlide) {
    return currentSlide.nextElementSibling
}

function getPrevSlide(currentSlide) {
    return currentSlide.previousElementSibling
}

function preloadingCarouselImages(slide, getterNextSlide, lazyLoad) {
    for (let i = 0, nextSlide = slide, nextImage; i < 3; i++) {
        if (!(nextSlide = getterNextSlide(nextSlide))) {
            break;
        }
        nextImage = nextSlide.getElementsByTagName("img").item(0)
        if (nextImage && !nextImage.classList.contains("loaded", "loading")) {
            lazyLoad.load(nextImage)
        }
    }
}

function createInteriorCarousel(lazyLoad, lazyLoadInstance) {

    function initInteriorCarousel() {
        const main = new Splide('#interior-main-carousel', {
            preloadPages: 2,
            perPage: 2,
            perMove: 1,
            type: 'loop',
            rewind: true,
            trimSpace: true,
            gap: '1.5rem',
            padding: '0%',
            rewindByDrag: false,
            pagination: false,
            arrows: true,
            flickPower: 2000,
            keyboard: true,
            dragMinThreshold: {
                mouse: 0,
                touch: 50,
            },
            breakpoints: {
                768: {
                    type: 'slide',
                    perPage: 1,
                    padding: '3rem',
                    arrows: false,
                },
                1024: {
                    perPage: 2,
                }
            },
        });


        const thumbnails = new Splide('#interior-thumbnail-carousel', {
            focus: "center",
            drag: true,
            trimSpace: false,
            snap: false,
            perMove: 1,
            keyboard: true,
            arrows: false,
            fixedWidth: 60,
            gap: 10,
            rewind: false,
            rewindByDrag: false,
            pagination: false,
            padding: { left: '1rem', right: '1rem' },
            isNavigation: true,
            dragMinThreshold: {
                mouse: 0,
                touch: 50,
            },
        })

        main.sync(thumbnails);
        main.mount();
        thumbnails.mount();

        if (lazyLoadInstance) {
            lazyLoadInstance.update()

            if (lazyLoad) {
                const slides = main.Components.Slides

                main.on('moved', function (newIndex, prevIndex, destIndex) {
                    preloadingCarouselImages(slides.getAt(destIndex).slide, (destIndex - prevIndex) < 0 ? getPrevSlide : getNextSlide, lazyLoad)
                });
            }
        }
    }
    return initInteriorCarousel
}

// module.exports = createInteriorCarousel
export { createInteriorCarousel };